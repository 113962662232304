<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    :validatePermission="false"
    @save="onSave('Reminders')"
    @cancel="onCancel('Reminders')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'ReminderEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Reminders',
      breadcrumbs: [
        {
          text: 'Напоминания',
          route: { name: 'Reminders' },
        },
        {
          text: 'Редактирование напоминания',
        },
      ],
      pageHeader: 'Редактирование напоминания',
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      employeeAccounts: (state) => state.common.employeeAccounts,
      reminderRelatedObjectTypes: (state) =>
        state.common.reminderRelatedObjectTypes,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'accountId',
              label: 'Аккаунт',
              list: this.employeeAccounts.data,
            },
            {
              type: 'fullDateTime',
              key: 'whenRemind',
              label: 'Дата напоминания',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm:ss',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
                disabledDate: this.dateDisabled,
                disabledTime: this.timeToDisabled,
              },
            },
            {
              type: 'textarea',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'select',
              key: 'relatedObjectType',
              label: 'Тип связанного объекта',
              list: this.reminderRelatedObjectTypes.data,
              disabled: true,
            },
            {
              type: 'text',
              key: 'relatedObjectId',
              label: '№ задания по телемаркетингу',
              disabled: true,
            },
            {
              type: 'fullDateTime',
              key: 'created',
              label: 'Время создания',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm:ss',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
              },
              disabled: true,
            },
            {
              type: 'text',
              key: 'createdBy',
              label: 'Создано',
              disabled: true,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const employeeAccounts = this.getCommonList({
      name: 'EmployeeAccounts',
    });

    const reminderRelatedObjectTypes = this.getCommonList({
      name: 'ReminderRelatedObjectTypes',
    });

    const item = this.getItem({
      name: this.controllerName,
      id: this.id,
    }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });

    Promise.all([employeeAccounts, reminderRelatedObjectTypes, item]).finally(
      () => {
        this.isLoadingPage = false;
      }
    );
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      getCommonList: 'common/getCommonList',
      updateItem: 'editableList/updateItem',
      getListforCommon: 'editableList/getListforCommon',
    }),

    dateDisabled(date) {
      const currentDate = new Date();

      return date < new Date(currentDate.setHours(0, 0, 0, 0));
    },

    timeToDisabled(date) {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      return date < new Date(currentDate.setHours(hours, minutes + 10, 0, 0));
    },
  },
};
</script>
